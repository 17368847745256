import React, { useState, useMemo } from "react";
import {Link} from "react-router-dom";
import styles from "./SubMenu.module.css";
import { useLocation } from 'react-router-dom'


const SubMenu = ({ item ,isActive,setIsActive}) => {
  
  const [currentPath, setCurrentPath] = useState("");
  const router = useLocation();
  useMemo(() => {
    setCurrentPath(router.pathname);
    
   
  }, [router]);

  return (
    <>
      <Link
        to={(item.subNav==undefined&&(currentPath.split("/")[2] == item.path.split("/")[2]?currentPath:item.path))}
        onClick={()=>{
         
          // if(isActive==item.path.split("/")[1])
          // setIsActive()
          // // else{

          // //   setIsActive(item.path.split("/")[1])
          // // }
          // else {
          //   if(item?.onClick == "true" ){
          //     var sideNavbar = document.getElementById("sideNavbar")
          //     setTimeout(()=>{

          //       sideNavbar.scrollTop = 800;
          //     },50)
          //     console.log( document.getElementById("sideNavbar").scrollTop,document.getElementById("sideNavbar"), document.getElementById("sideNavbar").scrollHeight,"first")
          //   setIsActive(item.path.split("/")[1])
          //   }
          //   else{

          //     setIsActive(item.path.split("/")[1])
          //   }

          // }
          if(isActive==item.path.split("/")[1])
          setIsActive()
          else {
            if(item?.onClick == "true" ){
              var sideNavbar = document.getElementById("sideNavbar")
              setTimeout(()=>{

                sideNavbar.scrollTop = 800;
              },50)
              console.log( document.getElementById("sideNavbar").scrollTop,document.getElementById("sideNavbar"), document.getElementById("sideNavbar").scrollHeight,"first")
            setIsActive(item.path.split("/")[1])
            }
            else{

              setIsActive(item.path.split("/")[1])
            }

          }
        
        

       
         } }
        
        className={`${styles.sidebarLink} ${
          currentPath.split("/")[1] == item.path.split("/")[1] && "sidebarLinkActive"
        }`}
      >
        <div >
        {item.icon}
          <span className="ml-1">{item.title}</span>
        </div>
        
        <div style={{display:"flex"}}>
       
          {item.subNav && currentPath.split("/")[1] == item.path.split("/")[1]
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </Link>
   
      {(isActive == item.path.split("/")[1]
      &&item.subNav) &&
        item.subNav.map((item, index) => {
          return (
            <Link
              to={item.path}
              key={index}
              
              className={`${styles.sidebarLink2} ${
                currentPath == item.path && "sidebarLinkActive2"
              }`}
              style={{display:"flex",justifyContent:"space-between"}}
            >
              {item.icon}
              {item.title}
          
            </Link>
          );
        })}
    </>
  );
};

export default SubMenu;
