import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import MultiStepForm from "../../utils/MultiStepForm";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import Reload from "../../utils/Reload"

import { FormStructure } from "./FormStructure";
import {
  episode_create,
  episode_delete,
  episode_update,
  all_episode_list,
  // episode_audio_file_delete,
  // episode_subtitle_delete
} from "../../../actions/WebSeries/episode";
import {
  subtitle_delete,
  audio_file_delete,

} from "../../../actions/subtitle_delete";
// import { all_season_list } from "../../../actions/WebSeries/season";
// import { all_advertisement_list } from "../../../actions/Advertiser/advertisement";
import { all_series_list } from "../../../actions/WebSeries/series";
import { all_language_list } from "../../../actions/Masters/language";
import { all_cast_list } from "../../../actions/Masters/cast";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import Popup from "../../utils/Popup";
import Export from "../../utils/Export";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_season_list } from "../../../actions/WebSeries/season";
export default function Episode() {
  const user = useSelector((state) => state.layout.profile);
  const role = useSelector((state) => state.layout.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rights = useSelector((state) => state.layout.rights);
  const location = useLocation();
  const tempTableData = {
    ...TableData(),
    deleteRecord: episode_delete,
    updateRecord: episode_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  const [open, setOpen] = useState(false);
  const [adPosition, setAdPosition] = useState();
  const [flag, setFlag] = useState(false);

  const [subtitlePosition, setSubtitlePosition] = useState();
  const [audioPosition, setAudioPosition] = useState();
  const [subTitleID, setsubTitleID] = useState("");
  const [audioFileId, setAudioFileId] = useState("");

  const [result, setResult] = useState(undefined);

  const [content, setPopupContent] = useState("");
  const [openAdError, setOpenAdError] = useState(false);

  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );

  const formTitle = [
    "Episode Details",
    "Episode Resolution",
    // "Audio Files",
    "Age Restrictions & Downloads",
    // role != "Producer" && "Advertisement Details",
  ].filter((e) => e);
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const tempFormStruct = FormStructure(isEdit);
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  const path = location?.pathname.split("/")[2];

  useEffect(() => {
    setView(path != "Episode" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {
        // subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        // audio_language: "Inactive",
      }
    );
    setIsEdit(path == "EditEpisode");
  }, [location]);

  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const episodes = useSelector((state) => state.webseries.episodes);
  // const advertisements = useSelector(
  //   (state) => state.advertisers.advertisements
  // );
  const language = useSelector((state) => state.masters.languages);
  const seasons = useSelector((state) => state.webseries.season);
  // console.log(seasons, "10000");
  useEffect(() => {
    if(user){

      const data = new FormData();
      data.append("id", user?.id);
      dispatch(all_episode_list(data));
    }
    // data.append("series_type", "Web Series");
    dispatch(all_series_list());
    dispatch(all_cast_list());
    dispatch(all_genre_list());
    dispatch(all_language_list());
    // dispatch(all_advertisement_list(data));
    dispatch(all_season_list());
  }, [user]);
  const series = useSelector((state) => state.webseries.series);

  useMemo(() => {
    if (episodes?.statuscode == 200) {
      const temp = tableData;
      // const episodeSubtitle = [];
      // episodes?.data.map((value) => {
      //   // console.log(value, "value");
      //   const subtitle = {};
      //   value?.subtitle_list &&
      //     value?.subtitle_list?.map((ele, index) => {
      //       // console.log(ele?.subtitle_file, ele?.subtitle_language, "idd");
      //       subtitle["subtitle_language_" + index] = ele?.subtitle_language;
      //       subtitle["subtitle_file_" + index] = ele?.subtitle_file;
      //       subtitle["subtitle_id_" + index] = ele?.id;
      //     });
      //   episodeSubtitle.push(subtitle);
      // });

      // const episodeAudio = [];
      // episodes?.data.map((value) => {
      //   // console.log(value, "value");
      //   const Audio = {};
      //   value?.audio_file_list &&
      //     value?.audio_file_list?.map((ele, index) => {
      //       // console.log(index, "idd");
      //       Audio["audio_language_" + index] = ele?.audio_language;
      //       Audio["audio_file_" + index] = ele?.audio_file;
      //       Audio["audio_id_" + index] = ele?.id;
      //       // console.log(index, ele?.id, "12301230");
      //     });
      //   episodeAudio.push(Audio);
      // });
      temp.tableBody = episodes?.data.map((ele, index) => ({
        ...ele,
        // ...episodeAdvertise[index],
        // ...episodeSubtitle[index],
        // ...episodeAudio[index],
        uploaded_by: ele?.series_name?.created_by?.firstName,
        series_name: ele?.series_name?.series_name,
        series_ownership:ele?.series_name?.series_ownership,
        // episode_cast: ele?.episode_cast?.map((cast) => cast.cast_name),
        // episode_genre: ele?.episode_genre?.map((genre) => genre.genre_title),
        season: ele?.season_name,
      }));
      setTableData({ ...temp });
      setForm({ ...form });
    }
  }, [episodes]);
  // console.log(episodes, "lklk");


  // const handleAddSubtitle = () => {
  //   const temp = formStructure;
  //   if (formStructure[3].length - 4 < language?.data.length * 3) {
  //     // setForm({...form,subtitle_count:form?.subtitle_count+1})
  //     const randomName = (formStructure[3].length - 4) / 3;
  //     temp[3].push(
  //       {
  //         id: "21",
  //         type: "select",
  //         title: "Select Language",
  //         size: "6",
  //         name: "subtitle_language_" + randomName,
  //         options: temp[3][5]["options"],
  //       },
  //       {
  //         id: "19",
  //         type: isEdit ? "lockedInput" : "inputBox",
  //         subtitle: "*",
  //         title: "Browse Subtitle file(.srt)",
  //         size: "5.5",
  //         name: "subtitle_file_" + randomName,
  //         endsWith: ".srt",
  //       errorText: "File extension should be .srt only",
  //         required: true,
  //       },

  //       {
  //         id: "23",
  //         type: "button",
  //         title: <RemoveIcon sx={{ color: "#fff !important" }} />,
  //         align: "right",
  //         size: ".5",
  //         padding: "2px 2px",
  //         position: randomName,
  //         forceShow: true,
  //         handleClick: () => {
  //           setOpen(true);
  //           setSubtitlePosition(randomName);
  //           setsubTitleID(form["subtitle_id_" + randomName]);
  //           setPopupContent(
  //             "Do you want to remove this subtitle from this Episode?"
  //           );
  //         },
  //       }
  //     );

  //     setFormStructure([...temp]);
  //   } else {
  //     setOpenAdError(true);
  //     setPopupContent(
  //       "You can add max " + language?.data.length + " subtitles in a Episode"
  //     );
  //   }
  // };
  // const handleAddAudioFile = (e) => {
  //   // console.log(e.currentTarget,"1111");
  //   const temp = formStructure;

  //   if (formStructure[2].length - 2 < language?.data.length * 3) {
  //     // console.log(form, "tr");

  //     const randomName = (formStructure[2].length - 2) / 3;
  //     // console.log(randomName, "22222");

  //     temp[2].push(
  //       {
  //         id: "21",
  //         type: "select",
  //         title: "Select Language",
  //         size: "6",
  //         name: "audio_language_" + randomName,
  //         options: temp[2][3]["options"],
  //       },
  //       {
  //         id: "19",
  //         type: isEdit ? "lockedInput" : "inputBox",
  //         subtitle: "*",
  //         title: "Browse mp3 file(.mp3)",
  //         name: "audio_file_" + randomName,
  //         size: "5.5",
  //         endsWith: ".wav",
  //       errorText: "File extension should be .mp3 or .wav only",
  //         required: true,
  //       },

  //       {
  //         id: "23",
  //         type: "button",
  //         title: <RemoveIcon sx={{ color: "#fff !important" }} />,
  //         align: "right",
  //         size: ".5",
  //         padding: "2px 2px",
  //         position: randomName,
  //         forceShow: true,
  //         handleClick: () => {
  //           setOpen(true);
  //           setAudioPosition(randomName);
  //           setAudioFileId(form["audio_id_" + randomName]);
  //           setPopupContent(
  //             "Do you want to remove this audio file from this Episode?"
  //           );
  //         },
  //       }
  //     );

  //     setFormStructure([...temp]);
  //   } else {
  //     setOpenAdError(true);
  //     setPopupContent(
  //       "You can add max " + language?.data.length + " audio file in a Episode"
  //     );
  //   }
  // };
  useMemo(() => {
    if (isEdit) {
      // console.log(form , "new Form")
      setView("create_new");
      // if (form?.subtitle_list?.length > (formStructure?.[3]?.length - 4) / 3)
      //   form?.subtitle_list.map(
      //     (ele) =>
      //       form?.subtitle_list?.length > (formStructure?.[3]?.length - 4 ) / 3 &&
      //       handleAddSubtitle()
      //   );
      // if (form?.audio_file_list?.length > (formStructure?.[2]?.length - 2) / 3)
      //   form?.audio_file_list.map(
      //     (ele) =>
      //       form?.audio_file_list?.length >
      //         (formStructure?.[2]?.length - 2) / 3 && handleAddAudioFile()
      //   );
    } else {
      setView("view_all");

      setForm({
        // subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        // audio_language: "Inactive",
      });
    }
    const temp = formStructure;
    tempFormStruct.map((arr, i) =>
      arr.map(
        (ele, j) =>
          !["select", "select_multiple"].includes(ele.type) &&
          (temp[i][j] = ele)
      )
    );
    // temp[4][0]["handleClick"] = handleClick;
    // temp[3][4]["handleClick"] = handleAddSubtitle;
    // temp[2][2]["handleClick"] = handleAddAudioFile;
    setFormStructure([...temp]);
  }, [isEdit, language, location]);

  // const casts = useSelector((state) => state.masters.casts);
  // const genre = useSelector((state) => state.masters.genre);
  useMemo(() => {
    if (series?.statuscode == 200) {
      const temp = formStructure;
      temp[0][0]["options"] = series?.data
        .map(
          (series) => series?.series_name
        )
        .filter((e) => e);

      setFormStructure([...temp]);
    }
    if (series?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData["filterColumn"][0]["options"] = series?.data.map(
        (series) => series?.series_name
      ).filter((e) => e);

      setTableData({ ...tempTableData });
    }
  }, [series]);
  // console
  useMemo(() => {
    if (seasons?.statuscode == 200) {
      const temp = formStructure;
      temp[0][1]["options"] = seasons?.data
        ?.map((season) => season?.series_name == form?.series_name && season?.season)
        .filter((e) => e);

      setFormStructure([...temp]);
    }
    // if (seasons?.statuscode == 200) {
    //   const tempTableData = tableData;
    //   tempTableData["filterColumn"][1]["options"] = seasons?.data;
    //   setTableData({ ...tempTableData });
    // }
  }, [form?.series_name]);
  // console.log(seasons, "858585");
  // useMemo(() => {
  //   if (casts?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[0][7]["options"] = casts?.data.map((cast) => cast?.cast_name);

  //     setFormStructure([...temp]);
  //   }
  // }, [casts]);
  // useMemo(() => {
  //   if (genre?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[0][8]["options"] = genre?.data.map((genre) => genre?.genre_title);

  //     setFormStructure([...temp]);
  //   }
  // }, [genre]);
  // useMemo(() => {
  //   if (casts?.statuscode == 200) {
  //     const temp = formStructure;
  //     temp[0][8]["options"] = casts?.data.map((cast) => cast?.cast_name);

  //     setFormStructure([...temp]);
  //   }
  // }, [casts]);
  // useMemo(() => {
  //   if (language?.statuscode == 200) {
  //     const temp = formStructure;

  //     temp[3][5]["options"] = language?.data.map(
  //       (language) => language?.language_name
  //     );
  //     temp[2][3]["options"] = language?.data.map(
  //       (language) => language?.language_name
  //     );
  //     setFormStructure([...temp]);
  //   }
  // }, [language]);
  // useMemo(() => {
  //   if (advertisements?.statuscode == 200) {
  //     const temp = formStructure;
  //     const currentDate = new Date();
  //     temp[4][1]["options"] = advertisements?.data
  //       .map(
  //         (advertisement) =>
  //           advertisement?.status == "Active" && advertisement?.product_name
  //       )
  //       .filter((e) => e);

  //     setFormStructure([...temp]);
  //   }
  // }, [advertisements]);
  // useMemo(() => {
  //   if (form?.subtitles == "Active") {
  //     const temp = formStructure;
  //     temp[3].map((ele, index) => index >= 4 && (ele["display"] = "block"));
  //     setForm({ ...form, subtitle_count: 1 });
  //     setFormStructure([...temp]);
  //   } else {
  //     const temp = formStructure;
  //     temp[3].map((ele, index) => index >= 4 && (ele["display"] = "none"));
  //     setFormStructure([...temp]);
  //   }
  // }, [form?.subtitles]);
  // useMemo(() => {
  //   if (form?.audio_language == "Active") {
  //     const temp = formStructure;
  //     temp[2].map((ele, index) => index >= 2 && (ele["display"] = "block"));
  //     setForm({ ...form });
  //     setFormStructure([...temp]);
  //   } else {
  //     const temp = formStructure;
  //     temp[2].map((ele, index) => index >= 2 && (ele["display"] = "none"));
  //     setFormStructure([...temp]);
  //   }
  // }, [form?.audio_language]);
  useMemo(() => {
    if (form?.publish_type == "Later") {
      const temp = formStructure;
      temp[0][5]["display"] = "block";
      temp[0][6]["display"] = "block";

      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0][5]["display"] = "none";
      temp[0][6]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [form?.publish_type]);

  const message = useSelector((state) => state.layout.message);

  const handleSubmit = (event) => {
    event.preventDefault();
    const tempForm = form;
    // console.log(form,"print form")
    tempForm["tempseries_name"] = form?.["series_name"];
    // tempForm["tempepisode_cast"] = form?.["episode_cast"];
    // tempForm["tempepisode_genre"] = form?.["episode_genre"];

    tempForm["tempseason"] = form?.["season"];
    tempForm["series_name"] = series?.data
      .map(
        (option) => form?.["series_name"]?.includes(option.series_name) && option.id
      )
      .filter((e) => e);
    // tempForm["episode_cast"] = casts?.data
    //   .map(
    //     (option) =>
    //       form?.["episode_cast"]?.includes(option.cast_name) && option.id
    //   )
    //   .filter((e) => e);
    // tempForm["episode_genre"] = genre?.data
    //   .map(
    //     (option) =>
    //       form?.["episode_genre"]?.includes(option.genre_title) && option.id
    //   )
    //   .filter((e) => e);

    tempForm["season"] = seasons?.data
      ?.map((option) =>
        form?.["series_name"] == option?.series
          ? form?.["season"]?.includes(option?.season) && option?.id
          : null
      )
      .filter((e) => e);
    // tempForm["series_type"] = "Web Series";
    tempForm["uploaded_by"] = user?.id;
    // tempForm["advertise"] = JSON.stringify(advertise);
    setForm({
      ...tempForm,
    });

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((structure) =>
      structure.map((element) => {
        if (
          element.type == "image" &&
          form?.[element.name] &&
          typeof form?.[element.name] != "string"
        ) {
          const temp = form;
          temp["temp" + element.name] = form?.[element.name];
          temp[element.name] = URL.createObjectURL(form?.[element.name]);

          setForm({
            ...temp,
          });
        }
      })
    );
    const temp = tableData;
    if (isEdit) {
      dispatch(episode_update(data));
    } else {
      dispatch(episode_create(data));
    }
    setFlag(true);
  };
  // console.log(episodes, "654654");
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });

      setIsEdit(false);
      setForm({
        // subtitles: "Inactive",
        episode_is_for18Plus: "No",
        allow_download: "Yes",
        // audio_language: "Inactive",
      });
      setFlag(false);
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);
        // data.append("series_type", "Web Series");
        dispatch(all_episode_list(data));
        navigate("/Episode/Episode/", { state: { view: "view_all" } });
        setView("view_all");
      }, 1000);
      setView("view_all");
    } else {
      const tempForm = form;
      tempForm["series_name"] = form?.tempseries_name;
      // tempForm["series_cast"] = form?.tempseries_cast;
      tempForm["season"] = form?.tempseason;
      tempForm["episode_thumbnail"] = form?.tempepisode_thumbnail;
      tempForm["episode_poster"] = form?.tempepisode_poster;
      setForm({ ...tempForm });
    }
  }, [message]);

  const videoEl = useRef(null);
  const handleLoadedMetadata = () => {
    const video = videoEl.current;
    var d = Number(video.duration);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = (h > 9 ? h : "0" + h) + ":";
    var mDisplay = (m > 9 ? m : "0" + m) + ":";
    var sDisplay = s > 9 ? s : "0" + s;

    setForm({ ...form, episode_duration: hDisplay + mDisplay + sDisplay });
  };

  // useMemo(() => {
  //   if (result) {
  //     const temp = formStructure;
  
  //     if (subtitlePosition != undefined) {
  //       temp[3].map((value, index) => {
  //         if (value.position == subtitlePosition) {
  //           const tempForm = form;
  //           tempForm["subtitle_language_" + value.position] = "";
  //           tempForm["subtitle_file_" + value.position] = "";
  //           setForm({ ...tempForm });
  //           temp[3].splice(index - 2, 3);
  //         }
  //       });
  //       setFormStructure([...temp]);
  //       setSubtitlePosition(undefined);
  //       dispatch(subtitle_delete({ id: subTitleID, video_type: "Episode" }));
  //     } else if (audioPosition != undefined) {
  //       temp[2].map((value, index) => {
  //         if (value.position == audioPosition) {
  //           // console.log(value.position, "8888")
  //           const tempForm = form;
  //           tempForm["audio_file_" + value.position] = "";
  //           tempForm["audio_language_" + value.position] = "";
  //           setForm({ ...tempForm });
  //           temp[2].splice(index - 2, 3);
  //         }
  //       });
  //       setFormStructure([...temp]);
  //       setSubtitlePosition(undefined);
  //       dispatch(audio_file_delete({ id: audioFileId, video_type: "Episode" }));

  //     }
  //   }
  //   setResult(undefined);
  // }, [result]);
  // useEffect(() => {
  //   const temp = formStructure;
  //   // temp[4][0]["handleClick"] = handleClick;
  //   temp[3][4]["handleClick"] = handleAddSubtitle;
  //   temp[2][2]["handleClick"] = handleAddAudioFile;
  //   setFormStructure([...temp]);
  // }, []);
  

  const handleClose = () => {
    setOpenAdError(false);
  };
 
  // useMemo(() => {
  //   var temp = form;

  //   if (isEdit) {
  //     var flag = true;
  //     for (var i = 0; i < episodes?.data.length; i++) {
  //       if (episodes?.data[i]?.id == form?.id) {
  //         if (episodes?.data[i]?.series_name == form?.series_name) {
  //           flag = false;
  //         }
  //         break;
  //       }
  //     }

  //     if (flag) {
  //       temp = {
  //         subtitles: "Inactive",
  //         episode_is_for18Plus: "No",
  //         allow_download: "Yes",
  //         audio_language: "Inactive",
  //       };
  //       temp["id"] = form?.id;
  //       temp["series_name"] = form?.series_name;
  //     }
  //   } else {
  //     temp = {
  //       subtitles: "Inactive",
  //       episode_is_for18Plus: "No",
  //       allow_download: "Yes",
  //       audio_language: "Inactive",
  //     };
  //     temp["series_name"] = form?.series_name;
  //     // temp["season"]=form?.season
  //   }

  //   series?.data.filter((series) => {
  //     if (form?.series_name == series?.series_name)
  //       temp["series_type"] = series?.series_type;
  //   });

  //   setForm({ ...temp });
  // }, [form?.series_name]);
  // const subtitles = language?.data?.map((ele, index) =>
  //   eval("form?.subtitle_language_" + index)
  // );
  // const audioFile = language?.data?.map((ele, index) =>
  //   eval("form?.audio_language_" + index)
  // );
  // useMemo(() => {
  //   const newLanguageList = language?.data
  //     ?.map(
  //       (ele, index) =>
  //         !subtitles.includes(ele?.language_name) && ele?.language_name
  //     )
  //     .filter((e) => e);
  //   const newAudioList = language?.data
  //     ?.map(
  //       (ele, index) =>
  //         !audioFile.includes(ele?.language_name) && ele?.language_name
  //     )
  //     .filter((e) => e);
  //   const temp = formStructure;
  //   temp[3].map(
  //     (ele, index) =>
  //       ele?.type == "select" && (temp[3][index]["options"] = newLanguageList)
  //   );
  //   temp[2].map(
  //     (ele, index) =>
  //       ele?.type == "select" && (temp[2][index]["options"] = newAudioList)
  //   );
  //   setFormStructure([...temp]);
  // }, [form]);
  return (
    <>
      <video
        style={{ display: "none" }}
        id="myvid"
        src={form?.episodeMp4_URL}
        ref={videoEl}
        type="video/mp4"
        onLoadedMetadata={handleLoadedMetadata}
      ></video>
      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
      <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        create_new={"/Episode/CreateEpisode/"}
        view_all={"/Episode/Episode"}
        form={form}
        access={rights?.["TV Shows"]?.["create"] == "true"}
        export_excel={
          <Export
            fileName={"Web Episode"}
            access={rights?.["TV Shows"]?.["export_excel"] == "true"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}

      />

      {view == "create_new" && (
        <MultiStepForm
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Episode/EditEpisode/"}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
    </>
  );
}
