import React from "react"
export  const FormStructure= (isEdit) => {
    return [
//    {
//         id:"1",
//         type:"select",
//         title:"Video Category",
//         name:"movie_category",
//         options:[],
//         required:true
//     },
    // {
    //     id:"2",
    //     type:"select",
    //     title:"Subcategory",
    //     name:"movie_subcategory",
    //     options:[],
    //     required:true
    // }
    
    // ,
    {
        id:"3",
        type:"inputBox",
        title:"Name",
        name:"movie_name",
        maxLength:"25",
			regex:/^[a-zA-Z0-9\s\&]+$/,
            isCapitalise:true,
            required:true
    }
    ,
    {
        id:"4",
        type:"select",
        title:"Language",
        name:"movie_language",
        options:["English","Hindi","Gujarati"],
        required:true
    }
    ,
    {
        id:"5",
        type:"select_multiple",
        title:"Select Genre",
        name:"movie_genre",
        maxSelections:"3",
        errorText:"You can select max 3 genre at a time",
        options:[],
        required:true
        
    },
    {
        id:"6",
        type:"inputBox",
        variant:"date",
        title:"Release Date",
        min:new Date().toISOString().split('T')[0],
        name:"release_date",
        required:true
        
    },
    {
        id:"7",
        type:"select_multiple",
        title:"Select Cast",
        name:"movie_cast",
        maxSelections:"7",
        errorText:"You can select max 7 cast at a time",
        options:[],
        // required:true
        
    },
 
    {
        id:"9",
        type:isEdit?"lockedInput":"inputBox",
        title:"Trailer URL (.m3u8)",
        endsWith:".m3u8",
        errorText:"File extension should be .m3u8 only",
        name:"movie_trailer",
        required:true
    }, 
    
    {
        id:"8",
        type:"description",
        title:"Description",
        name:"movie_description",
        required:true
    }, 
    
    {
        id:"18",
        type:"image",
        title:"Browse Thumbnail",
        subtitle:"(Resolution : 910 x 1280) *",
        name:"movie_thumbnail",
        subsubsubtitle:"Support only JPG,PNG,JPEG",
        subsubtitle:"Max File Size 1MB",
        required:true
    }, {
        id:"19",
        type:"image",
        subtitle:"(Resolution : 1280 x 720) *",
        title:'Browse Poster',
        subsubsubtitle:"Support only JPG,PNG,JPEG",
        subsubtitle:"Max File Size 1MB",
        name:"movie_poster",
        size:4,
        required:true
    }
    ,
    {
        id:"25",
        type:"button",
        title:"Create",
        
    }

]
}