

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout/Layout';
import UpcomingMovie from '../components/Movie/UpcomingMovie/UpcomingMovie';
function UpcomingMovieModule() {
  return (
    <div className="App">
    
<Routes>


< Route path='/UpcomingMovie' element={<Layout children={UpcomingMovie()}/>}/>
< Route path='/CreateUpcomingMovie' element={<Layout children={UpcomingMovie()}/>}/>
< Route path='/EditUpcomingMovie' element={<Layout children={UpcomingMovie()}/>}/>



{/* < Route path='/MovieDetails' element={<Layout children={ProductDetails()}/>}/> */}
      </Routes>
    </div>
  );
}

export default UpcomingMovieModule;
