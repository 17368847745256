import React from "react";
import { useState, useMemo, useEffect } from "react";
import ListTable from "../utils/Table";
import Form from "../utils/Form";
import ViewChange from "../utils/ViewChange";
import {
  subscription_create,
  subscription_delete,
  subscription_update,
  all_subscription_list,
} from "../../actions/subscription";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Popup from "./../utils/Popup";
import { all_country_list } from "./../../actions/Masters/country";
export default function Subscription() {
  const user = useSelector((state) => state.layout.profile);

  const dispatch = useDispatch();
  const rights = useSelector((state) => state.layout.rights);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [result, setResult] = useState(undefined);
  const [content, setPopupContent] = useState("");
  const [position, setPosition] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const path = location?.pathname.split("/")[2];
  useEffect(() => {
    setView(path != "Subscriptions" ? "create_new" : "view_all");
    setForm((location?.state?.form && JSON.parse(location?.state?.form)) || {});
    setIsEdit(path == "EditSubscriptions");
  }, [location]);
  const tempTableData = {
    tableTitle: "Subscriptions",
    //  deleteRecord: subscription_delete,
    disableDelete: true,
    updateRecord: subscription_update,
    deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "plan_name",
        label: "Plan Name",
      },
      {
        id: "duration",
        label: "Duration",
      },
      {
        id: "final_amount",
        label: "Price(₹)",
      },
      // {
      //   id: "price",
      //   label: "Price(₹)",
      // },
      {
        id: "restricrt_no_of_device",
        label: "Device Limit",
      },
      // {
      //   id: "profile_limit",
      //   label: "Profile Limit",
      // },
      {
        id: "status",
        label: "Status",
      },
      {
        id: "edit",
        label: "Update",
        access: rights?.["Masters"]?.["edit"] == "true",
      },
    ],
    tableBody: [
      {
        id: 0,
        plan_name: "Movie",
        select_duration: "Landscape",
        price: "2",
        status: "Active",
        edit: 0,
      },
    ],
    filterColumn: [
     
    ],
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);

  // const countries = useSelector((state) => state?.masters?.country);
  // console.log(countries,"new")

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "inputBox",
      title: "Plan Name",
      name: "plan_name",
      maxLength: "20",
      regex: /^[a-zA-Z\s]+$/,
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Select Duration",
      name: "duration",
      options: ["Yearly", "Monthly", "Half yearly", "Quarterly"],
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      title: "Allowed Device Limit",
      regex: /^[0-9\.]+$/,
      maxLength: "1",
      name: "restricrt_no_of_device",

      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      title: "Discounted Price",
      regex: /^[0-9\.]+$/,
      maxLength: "3",
      name: "final_amount",
    
      required: true,
      },
	{
		id: "4",
		type: "inputBox",
		title: "Price",
		regex: /^[0-9\.]+$/,
		maxLength: "3",
		name: "price",
  
		required: true,
	  },
    // {

    //   id: "2",
    //   type: "select",
    //   title: "Select Max Resolution",
    //   name: "max_resolution",
    //   options: ["360p", "480p", "720p", "1080p"],
    //   required: true,
    // },

    // {
    // 	id: "4",
    // 	type: "inputBox",
    // 	title: "Allowed Profile Limit",
    // 	regex:/^[0-9\.]+$/,
    // maxLength:"1",
    // 	name: "profile_limit",
    // },

    {
      id: "5",
      type: "description",
      title: "Description",
      name: "description",
	  size:"12"
    },

    {
      id: "7",
      type: "button",
      title: isEdit ? "Edit" : "Create",
    },
  ]);

//   useMemo(() => {
//     if (isEdit) {
//       setView("create_new");
//       if (form?.country_price.length - 1 > (formStructure.length - 9) / 3)
//         form?.country_price.map(
//           (ele) =>
//             form?.country_price.length - 1 > (formStructure.length - 9) / 3 &&
//             handleClick()
//         );
//     } else {
//       setView("view_all");
//       setForm({});
//     }
//     const tempForm = formStructure;
//     tempForm[4]["handleClick"] = handleClick;
//     setFormStructure([...tempForm]);
//   }, [isEdit]);

  const subscriptions = useSelector(
    (state) => state.subscriptions.subscriptions
  );
  useEffect(() => {
    dispatch(all_subscription_list());
    dispatch(all_country_list());
  }, []);
  useMemo(() => {
    if (subscriptions?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = subscriptions?.data.map((ele, index) => ({
        ...ele,
        price: parseFloat(ele.price).toFixed(2),
        final_amount: parseFloat(ele.final_amount).toFixed(2),

        
      }));
     

      setTableData({ ...temp });
    }
  }, [subscriptions]);

  const formTitle = isEdit ? "Edit Subscription" : "Create Subscription";
  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      dispatch(subscription_update(data));
    } else {
      dispatch(subscription_create(data));
    }
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });
      setIsEdit(false);
      setForm({});
      setTimeout(() => {
        dispatch(all_subscription_list());
        navigate("/Subscriptions/Subscriptions/", {
          state: { view: "view_all" },
        });
        setView("view_all");
      }, 1000);
    } else {
      setForm({ ...form });
    }
  }, [message]);

  return (
    <>
      {/* <ViewChange setForm={setForm} setView={setView} access={rights?.["Masters"]?.["create"]=="true"} setIsEdit={setIsEdit} view={view} isEdit={isEdit}/> */}

      <Popup
        open={open}
        setOpen={setOpen}
        content={content}
        setResult={setResult}
      />
      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          formTitle={formTitle}
          key={"Form"}
          handleSubmit={handleSubmit}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Subscriptions/EditSubscriptions/"}
        />
      )}
    </>
  );
}
