import React from "react";
import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/PageTitle.module.css";
import ListTable from "../../utils/Table";
import Form from "../../utils/Form";
import { TableData } from "./TableData";
import ViewChange from "../../utils/ViewChange";
import { FormStructure } from "./FormStructure";
import {
  series_create,
  series_delete,
  series_update,
  all_series_list,
} from "../../../actions/WebSeries/series";
import { all_producer_list } from "../../../actions/producer";
import { all_genre_list } from "../../../actions/Masters/genre";
import { all_language_list } from "../../../actions/Masters/language";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Export from "../../utils/Export";
import Reload from "../../utils/Reload";
export default function Series() {
  const user = useSelector((state) => state.layout.profile);
  const role = useSelector((state) => state.layout.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rights = useSelector((state) => state.layout.rights);
  const distributor = useSelector((state) => state.layout.distributor);
  const location = useLocation();
  const [view, setView] = useState(location?.state?.view || "view_all");
  const [isEdit, setIsEdit] = useState(false);
  const [flag, setFlag] = useState(false);

  const tempTableData = {
    ...TableData(),
		disableDelete: role !== "Producer" ? false: true,

    deleteRecord: series_delete,
    updateRecord: series_update,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText: "Are you Sure?",
  };
  const [tableData, setTableData] = useState({ ...tempTableData });
  useMemo(() => {
    setTableData({ ...tempTableData });
  }, [rights]);
  const [form, setForm] = useState(
    (location?.state?.form && JSON.parse(location?.state?.form)) || {}
  );
  const tempFormStruct = FormStructure(isEdit);
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);

  const [formTitle, setFormTitle] = useState("Show Details");
  useMemo(() => {
    if (isEdit) {
      setView("create_new");
      // const temp = formStructure
      // temp[11]["display"] = "block";
      // temp[11]["required"] = true;
    } else {
      setView("view_all");
      setForm({
        // series_distributor: role == "Producer" ? distributor?.name : "",
        // series_ownership: role == "Producer" ? "Collaborator" : "",
        // series_type: role == "Producer" ? "TVOD" : "",
      });
    }
    const temp = formStructure;
    tempFormStruct.map((ele, j) => (temp[j] = ele));
    setFormStructure([...temp]);
  }, [isEdit, role]);
  const path = location?.pathname.split("/")[2];
  useEffect(() => {
    setView(path != "Series" ? "create_new" : "view_all");
    setForm(
      (location?.state?.form && JSON.parse(location?.state?.form)) || {

        first_episode_free: "Yes",
        status: "Active",
        
      }
    );
    setIsEdit(path == "EditSeries");
  }, [location]);

  const series = useSelector((state) => state.webseries.series);

  useEffect(() => {
    const data = new FormData();
    data.append("id", user?.id);

    dispatch(all_series_list(data));
  }, [user]);

  useMemo(() => {
    if (series?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = series?.data.map((ele) => ({
        ...ele,
        language: ele?.language?.language_name,
        genre: ele?.genre.map((value) => value?.genre_title),
        series_distributor: ele?.series_distributor?.name,
        uploaded_by: ele?.created_by?.firstName,
      }));
     
    }
  }, [series]);
  useEffect(() => {
    dispatch(all_genre_list());
    dispatch(all_producer_list());
    dispatch(all_language_list());
    
  }, []);
  const genre = useSelector((state) => state.masters.genre);
  const language = useSelector((state) => state.masters.languages);
  const distributors = useSelector((state) => state.producer.producer);
useMemo(()=>{
  if (form?.series_ownership == "Collaborator") {
    const temp = formStructure;
    temp[1]["display"] = "block";
    temp[2]["display"] = "block";
    temp[6]["type"] = "inputBox";
    temp[6]["disabled"] = true
    setFormStructure([...temp]);
  } else {
    const temp = formStructure;
    temp[1]["display"] = "none";
    temp[2]["display"] = "none";
    setFormStructure([...temp]);
  }
},[form?.series_ownership])
  useMemo(() => {
    if (genre?.statuscode == 200) {
      const temp = formStructure;
      temp[5]["options"] = genre?.data.map((genre) => genre?.genre_title);

      setFormStructure([...temp]);
    }
  }, [genre ,form?.genre]);
  useMemo(() => {
    if (language?.statuscode == 200) {
      const temp = formStructure;
      temp[4]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setFormStructure([...temp]);
    }
    if (language?.statuscode == 200) {
      const tempTableData = tableData;
      tempTableData.filterColumn[1]["options"] = language?.data.map(
        (language) => language?.language_name
      );

      setTableData({ ...tempTableData });
    }
  }, [language,form?.language]);
  useMemo(() => {
    if (form?.series_ownership == "Collaborator"){
      setForm({ ...form, series_type: "TVOD" });
     

    }
  }, [form?.series_ownership]);
  useMemo(() => {
    if (distributors?.statuscode == 200) {
      // console.log(distributors, "disss")
      const temp = formStructure;
      temp[1]["options"] = distributors?.data
        .map((distributor) => distributor?.name)
        .filter((e) => e);

      setFormStructure([...temp]);
    }
  }, [distributors]);
  useMemo(() => {
    if (form?.series_distributor)
      setForm({
        ...form,
        company_name: distributors?.data
          .map(
            (option) =>
              form?.series_distributor == option.name && option.company_name
          )
          .filter((e) => e),
      });
  }, [form?.series_distributor]);
  // useMemo(() => {
  //   if (form?.admin_approval == "Reject") {
  //     const temp = formStructure;
  //     temp[12]["display"] = "block";
  //     temp[12]["required"] = true;
  //     setFormStructure([...temp]);
  //   } else if (form?.admin_approval == "Approve") {
  //     const temp = formStructure;
  //     temp[12]["display"] = "none";
  //     temp[12]["required"] = false;
  //     setFormStructure([...temp]);
  //   }
  // }, [form?.admin_approval]);
  useMemo(() => {
    if (form?.series_type == "TVOD") {
      const temp = formStructure;
      temp[7]["display"] = "block";
      temp[7]["required"] = true;
      temp[8]["display"] = "block";
      temp[8]["required"] = true;
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[7]["display"] = "none";
      temp[7]["required"] = false;
      temp[8]["display"] = "none";
      temp[8]["required"] = false;
      setFormStructure([...temp]);
    }
  }, [form?.series_type]);

  // useMemo(() => {
  //   console.log("9898989");
  //   if (role == "Producer") {
  //     if (form?.admin_approval == "Approve") {
  //       const temp = formStructure;
  //       temp[13]["display"] = "block";
  //       temp[13]["required"] = true;
  //       setFormStructure([...temp]);
  //     } else {
  //       const temp = formStructure;
  //       temp[13]["display"] = "none";
  //       temp[13]["required"] = false;
  //       setFormStructure([...temp]);
  //     }
  //   } else {
  //     const temp = formStructure;
  //     temp[13]["display"] = "block";
  //     temp[13]["required"] = true;
  //     setFormStructure([...temp]);
  //   }
  // }, [form]);


  const message = useSelector((state) => state.layout.message);
  const handleSubmit = (event) => {
    event.preventDefault();
    const temp_movie_category = form;
    temp_movie_category["tempgenre"] = form?.["genre"];
    temp_movie_category["templanguage"] = form?.["language"];
    temp_movie_category["tempseries_distributor"] =
    form?.["series_distributor"];
  temp_movie_category["series_distributor"] = distributors?.data
    .map(
      (option) =>
        form["series_distributor"]?.includes(option?.name) && option.id
    )
    .filter((e) => e);
    temp_movie_category["genre"] = genre?.data
      .map(
        (option) => form?.["genre"]?.includes(option.genre_title) && option.id
      )
      .filter((e) => e);
    temp_movie_category["language"] = language?.data
      .map(
        (option) =>
          form?.["language"]?.includes(option.language_name) && option.id
      )
      .filter((e) => e);
    temp_movie_category["created_by"] = user?.id;
    setForm({
      ...temp_movie_category,
    });

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("user", user?.id);
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      dispatch(series_update(data));
    } else {
      dispatch(series_create(data));
    }
    setFlag(true);
  };
  useMemo(() => {
    if (message?.statuscode == 200) {
      const temp = tableData;
      if (isEdit) {
        temp.tableBody.map(
          (value, index) =>
            value.id == form.id && (temp.tableBody[index] = { ...form })
        );
      } else {
        temp.tableBody[temp.tableBody.length] = {
          id: temp.tableBody.length,
          ...form,
          edit: temp.tableBody.length,
        };
      }
      setTableData({ ...temp });
      setIsEdit(false);
      setForm({});
      setFlag(false);
      setTimeout(() => {
        const data = new FormData();
        data.append("id", user?.id);

        dispatch(all_series_list(data));
        navigate("/Series/Series/", { state: { view: "view_all" } });
        setView("view_all");
      }, 1000);
    } else {
      const tempForm = form;
      tempForm["series_thumbnail"] = form?.tempseries_thumbnail;
      tempForm["series_poster"] = form?.tempseries_poster;
      tempForm["language"] = form?.templanguage;
      tempForm["genre"] = form?.tempgenre;
      setForm({ ...tempForm });
    }
  }, [message]);
  return (
    <>
  <ViewChange
        setForm={setForm}
        setView={setView}
        setIsEdit={setIsEdit}
        view={view}
        isEdit={isEdit}
        access={rights?.["TV Shows"]?.["create"] == "true"}
        create_new={"/Series/CreateSeries/"}
        view_all={"/Series/Series/"}
        form={form}
        export_excel={
          <Export
            fileName={"TV Shows"}
            access={rights?.["TV Shows"]?.["export_excel"] == "true"}
            isClubed={true}
            exportData={tableData?.exportData || tableData?.tableBody}
            headings={tableData.tableHead.map((value) => value.label)}
          />
        }
        reload={<Reload isClubed={true} />}
      />

      {view == "create_new" && (
        <Form
          formStructure={formStructure}
          handleSubmit={handleSubmit}
          formTitle={formTitle}
          key={"Form"}
          setForm={setForm}
          form={form}
          tableData={tableData}
          setTableData={setTableData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
      {view == "view_all" && (
        <ListTable
          tableData={tableData}
          key={"ListTable"}
          setForm={setForm}
          setTableData={setTableData}
          setIsEdit={setIsEdit}
          create_new={"/Series/EditSeries/"}
        />
      )}
    </>
  );
}
