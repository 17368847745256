import { useLocation } from "react-router-dom";

export const TableData = () => {
  const location = useLocation();
  // console.log(typeof(location?.state),"++++++++++")

  return {
    tableTitle: "Videos",
    onDeleteText: "Are you Sure?",
    onUpdateText: "Are you Sure?",
    tableHead: [
      {
        id: "movie_name",
        label: "Name",
        link: "/Movie/MovieDetails",
        color: "var(--gradientColor2)",
        subText: "movie_subcategory",
      },
      {
        id: 'movie_access',
        label: 'Access',
        // subText:"publish_time1"
      },
      {
        id: "movie_poster",
        label: "Image",
        isImage: true,
      },
      {
        id:"released_status",
        label:"Release Date"
      }
      ,
      {
        id: "genre",
        label: "Genre",
        subText1: "genre1",
      },
      

     
      {
        id: "movie_language",
        label: "Language",
      },
      // {
      //   id: "rental_count",
      //   label: "Total Rental",
      //   align: "center",
      // },

      // {
      //   id: "movieViews",
      //   label: "Views",
      //   align: "center",
      // },
      

      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,

      },

      {
        id: "edit",
        label: "Update",
      },
    ].filter((e) => e),
    tableBody: [],
    filterColumn: [
    
      // {
      //   title: undefined
      // },
      {
        id: "2",
        title: "Language",
        name: "movie_language",
        options: ["English", "Hindi", "Gujarati"],
      },
      {
        id: "3",
        title: "Category",
        name: "movie_category",
        default:
          typeof location?.state != "object" && location.state == null
            ? location?.state
            : null,
        options: ["Action", "Comedy", "Drama", "Horror"],
      },
      {
        id: "3",
        title: "Sub Category",
        name: "movie_subcategory",
        options: ["Action", "Comedy", "Drama", "Horror"],
        displayOn: "movie_category",
      },
      {
        id: "4",
        title: "Access",
        name: "movie_access",
        options: ["Free", "TVOD", "SVOD"],
      },
     
    ],
  };
};
