import React from "react";
import AddIcon from "@mui/icons-material/Add";
import SRTFile from "./../../../images/SRTFile.png";
export const FormStructure = (isEdit) => {
  return [
    [
      {
        id: "1",
        type: isEdit ? "inputBox" :"select",
        title: "Select Series",
        name: "series_name",
        options: ["Series", "Series", "Series"],
        required: true,
        disabled: isEdit ? "true" : "false",
      },

      {
        id: "2",
        type: isEdit ? "inputBox" :"select",
        title: "Select Season",
        name: "season",
        options: [],
        required: true,
        disabled: isEdit ? "true" : "false",
      },
      {
        id: "2",
        type: "select",
        title: "Select Episode No",
        name: "episode_number",
        options: [
          "Episode 1",
          "Episode 2",
          "Episode 3",
          "Episode 4",
          "Episode 5",
          "Episode 6",
          "Episode 7",
          "Episode 8",
          "Episode 9",
          "Episode 10",
          "Episode 11",
          "Episode 12",
          "Episode 13",
          "Episode 14",
          "Episode 15",
        ],
        required: true,
      },
      // {
      // 	id: "3",
      // 	type: "select",
      // 	title: "Access Type",
      // 	name: "access",
      // 	options: ["Free", "Pay Per View", "Premium"],
      // 	required:true
      // },
      {
        id: "4",
        type: "inputBox",
        title: "Episode Title",
        name: "episode_title",
        maxLength: 50,
        regex: /^[a-zA-Z0-9\s\&\"\,]+$/,
        required: true,
      },
      // {
      // 	id: "4",
      // 	type: "inputBox",
      // 	title: "Episode Number",
      // 	name: "episode_number",
      // 	variant:"Number",
      // 	required:true
      // },
      {
        id: "9",
        type: "select",
        title: "Publish Type",
        name: "publish_type",
        options: ["Now", "Later"],
        required: true,
      },
      {
        id: "5",
        type: "inputBox",
        variant: "date",
        title: "Release Date",
        name: "release_date",
        min: new Date().toISOString().split("T")[0],
        required: true,
        display: "none",
      },
      {
        id: "5",
        type: "inputBox",
        variant: "time",
        title: "Release Time",
        name: "publish_time",
        required: true,
        display: "none",
      },

      // {
      //   id: "7",
      //   type: "select_multiple",
      //   title: "Select Cast",
      //   name: "episode_cast",
      //   maxSelections: "3",
      //   errorText: "You can select max 3 cast at a time",
      //   options: ["Portrait", "Landscape"],
      //   required: true,
      // },
      // {
      //   id: "7",
      //   type: "select_multiple",
      //   title: "Select Genre",
      //   name: "episode_genre",
      //   maxSelections: "3",
      //   errorText: "You can select max 3 Genre at a time",
      //   options: ["Portrait", "Landscape"],
      //   required: true,
      // },
      {
        id: "4",
        type: "inputBox",
        title: "Description",
        name: "episode_description",
        maxLength: "1000",
        size: "12",
        isCapitalise: true,
        required: true,
      },
    ],
    [
      {
        id: "12",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "M3U8 URL",
        endsWith: ".m3u8",
        errorText: "File extension should be .m3u8 only",
        name: "episode_link",
        required: true,
      },
      {
            type: "undefined",
          },
      // {
      //   id: "11",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   title: "M3U8 URL ( Resolution: 720p)",
      //   endsWith: ".m3u8",
      //   errorText: "File extension should be .m3u8 only",
      //   name: "episodeLink_720p",
      //   required: true,
      // },
      // {
      //   id: "10",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   title: "M3U8 URL ( Resolution: 480p)",
      //   endsWith: ".m3u8",
      //   errorText: "File extension should be .m3u8 only",
      //   name: "episodeLink_480p",
      //   required: true,
      // },
      // {
      //   id: "9",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   title: "M3U8 URL ( Resolution: 360p)",
      //   endsWith: ".m3u8",
      //   errorText: "File extension should be .m3u8 only",
      //   name: "episodeLink_360p",
      //   required: true,
      // },

      {
        id: "13",
        type: isEdit ? "lockedInput" : "inputBox",
        title: "Upload Mp4 URL",
        endsWith: ".mp4",
        errorText: "File extension should be .mp4 only",
        name: "episodeMp4_URL",
        required: true,
      },
      {
        id: "6",
        type: "inputBox",
        title: "Episode duration",
        name: "episode_duration",
        disabled: true,
      },
      // ,

      // {
      // 	id: "14",
      // 	type: "inputBox",
      // 	variant: "number",
      // 	title: "Set Position",
      // 	name: "set_position",
      // 	required:true
      // }
      //   {
      //     id: "5",
      //     type: "image",
      //     title: "Browse Thumbnail",
      //     subtitle: "(Resolution : 910 x 1280)",
      //     subsubtitle: "Max File Size 1MB",
      //     subsubsubtitle: "Support only JPG,PNG,JPEG",
      //     name: "episode_thumbnail",
      //     required: true,
      //   },
      {
        id: "6",
        type: "image",
        title: "Browse Poster",
        subtitle: "(Resolution : 1280 x 720)",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        size: "4",
        name: "episode_poster",
        required: true,
      },
    ],
    // [
    //   {
    //     id: "23",
    //     type: "toggle",
    //     title: "Audio Language",
    //     name: "audio_language",
    //     default: "Inactive",
    //     size: "3",
    //     options: [
    //       { value: "Active", color: "success" },
    //       { value: "Inactive", color: "danger" },
    //     ],
    //   },
    //   {
    //     type: "undefined",
    //   },
    //   {
    //     id: "23",
    //     type: "button",
    //     title: <AddIcon sx={{ color: "#fff !important" }} />,
    //     align: "left",
    //     forceShow: true,
    //     display: "none",
    //   },
    //   {
    //     id: "21",
    //     type: "select",
    //     title: "Select Language",
    //     size: "6",
    //     display: "none",
    //     name: "audio_language_0",
    //     options: ["Advertise 1", "Advertise 2"],
    //   },
    //   {
    //     id: "19",
    //     type: isEdit ? "lockedInput" : "inputBox",
    //     subtitle: "*",
    //     title: "Browse mp3 file(.mp3)",
    //     name: "audio_file_0",
    //     size: "5.5",
    //     endsWith: ".wav",
    //     errorText: "File extension should be .mp3 or .wav only",
    //     display: "none",
    //     required: true,
    //   },
    // ],

    [
      // {
      //   id: "17",
      //   type: "toggle",
      //   title: "Subtitles",
      //   name: "subtitles",
      //   default: "Inactive",
      //   size: "4",
      //   options: [
      //     { value: "Active", color: "success" },
      //     { value: "Inactive", color: "danger" },
      //   ],
      // },
      {
        id: "18",
        type: "toggle",
        title: "Is This Video 18+ ?",
        name: "episode_is_for18Plus",
        size: "4",
        default: "No",
        options: [
          { value: "Yes", color: "success" },
          { value: "No", color: "danger" },
        ],
      },
      {
        id: "19",
        type: "toggle",
        title: "Allow Download ?",
        name: "allow_download",
        size: "4",
        default: "Yes",
        options: [
          { value: "Yes", color: "success" },
          { value: "No", color: "danger" },
        ],
      },
      {
        id: "20",
        type: "toggle",
        title: "Status",
        display:isEdit ? "block" : "none",
        name: "status",
        default: "Active",
        size: "3",
        options: [
          { value: "Active", color: "success" },
          { value: "Inactive", color: "danger" },
        ],
      },
      // {
      //   id: "23",
      //   type: "button",
      //   title: <AddIcon sx={{ color: "#fff !important" }} />,

      //   align: "left",
      //   display: "none",
      //   forceShow: true,
      // },
      // {
      //   id: "21",
      //   type: "select",
      //   title: "Select Language",
      //   size: "6",
      //   name: "subtitle_language_0",
      //   display: "none",
      //   options: ["Advertise 1", "Advertise 2"],
      // },
      // {
      //   id: "28",
      //   type: isEdit ? "lockedInput" : "inputBox",
      //   subtitle: "*",
      //   title: "Browse Subtitle file(.srt)",
      //   name: "subtitle_file_0",
      //   size: "5.5",
      //   endsWith: ".srt",
      //   errorText: "File extension should be .srt only",
      //   required: true,
      //   display: "none",
      // },
    ],
    // [
    //   {
    //     id: "23",
    //     type: "button",
    //     title: <AddIcon sx={{ color: "#fff !important" }} />,

    //     align: "left",
    //     forceShow: true,
    //   },
    //   {
    //     id: "21",
    //     type: "select",
    //     title: "Select Advertise",
    //     size: "3",
    //     name: "advertise0",
    //     options: ["Advertise 1", "Advertise 2"],
    //   },
    //   {
    //     id: "24",
    //     type: "inputBox",
    //     size: "1.5",
    //     title: "Ad Duration",
    //     name: "duration0",
    //     disabled: true,
    //   },
    //   {
    //     id: "24",
    //     type: "duration",
    //     // regex:/^([0]?[0-3]):([0-5]?[0-9]):([0-5]?[0-9])+$/,
    //     // default:"00:00:00",
    //     // maxLength:"8",
    //     // variant:"time",
    //     // min:"00:00",
    //     // max:"03:00",
    //     size: "2",
    //     title: "Add Duration",
    //     name: "time0",
    //   },

    //   {
    //     id: "24",
    //     type: "inputBox",
    //     size: "2",
    //     title: "Company Name",
    //     name: "company_name0",
    //     disabled: true,
    //   },
    //   {
    //     id: "24",
    //     type: "inputBox",
    //     size: "1.5",
    //     title: "View Required",
    //     name: "views_required0",
    //     disabled: true,
    //   },
    //   {
    //     id: "24",
    //     type: "inputBox",
    //     size: "1.5",
    //     title: "Avbl. views",
    //     name: "available_views0",
    //     disabled: true,
    //   },
    // ],
  ];
};
